import { NgModule } from '@angular/core';
import { RSCommonModule } from '../core/feature-modules/facade.module';
import { TableFilterModule } from '../core/feature-modules/table-filter/table-filter.module';
import { ReviewManagementComponent } from './review-management.component';
import { GoogleReviewsTabComponent } from './google-reviews-tab/google-reviews-tab.component';
import { PendingResponsesTabComponent } from './pending-responses-tab/pending-responses-tab.component';
import { ManageNotificationsTabComponent } from './manage-notifications-tab/manage-notifications-tab.component';
import { AddEditResponseComponent } from './components/add-edit-response/add-edit-response.component';
import { ConfirmationPopupComponent } from './components/confirmation-popup/confirmation-popup.component';
import { SuccessPopupComponent } from './components/success-popup/success-popup.component';
import { YelpReviewsTabComponent } from './yelp-reviews-tab/yelp-reviews-tab.component';
import { MultiReplyComponent } from './components/multi-reply/multi-reply.component';
import { DialogService } from 'primeng/dynamicdialog';
import { OverviewCardComponent } from './components/overview-card/overview-card.component';
import { RsElementsModule } from './../core/elements/rs-elements.module';
import { DeletePopupComponent } from './components/delete-popup/delete-popup.component';
import { PandioReviewsTabComponent } from './pandio-reviews-tab/pandio-reviews-tab.component';
import { ReviewsOverviewCardComponent } from './components/reviews-overview-card/reviews-overview-card.component';
import { ReviewResponseModule } from './response-templates-list-view/response-templates-list-view.module';
import { AddTemplateResponseComponent } from './components/add-template-response/add-template-response.component';
import { SurveyToolModule } from './survey-tool/survey-tool.component.module';

const modules = [
  RSCommonModule,
  TableFilterModule,
  RsElementsModule,
  ReviewResponseModule,
  SurveyToolModule,
];

const components = [
  ReviewManagementComponent,
  GoogleReviewsTabComponent,
  PendingResponsesTabComponent,
  ManageNotificationsTabComponent,
  AddEditResponseComponent,
  ConfirmationPopupComponent,
  SuccessPopupComponent,
  YelpReviewsTabComponent,
  MultiReplyComponent,
  OverviewCardComponent,
  DeletePopupComponent,
  PandioReviewsTabComponent,
  ReviewsOverviewCardComponent,
  AddTemplateResponseComponent,
];

const exports = [
  ReviewManagementComponent,
  ReviewsOverviewCardComponent,
  AddTemplateResponseComponent,
];

@NgModule({
  declarations: [...components],
  exports: [...exports],
  imports: [...modules],
providers: [DialogService],
})

export class ReviewManagementModule {}
