import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { generateJWTToken } from 'src/app/core/helpers/jwt-token.utils';

@Injectable({
  providedIn: 'root',
})
export class FeedbackSurveyService {
  constructor(private http: HttpClient) {}

  /**
   * @description To get the list of all Feedback surveys.
   * @param accountId account id.
   * @param userId user id.
   * @returns observable.
   */
  public getFeedbackSurveysList(accountId: number, userId: number, page: number = 1): Observable<any> {
    // const payload = {'accounts': [85, 86, 87, 88, 171, 172, 173, 175, 495]}; // Add accounts array here.
    const payloadObj = {
      iss: 'https://platform.renderseo.com', // Might have to change this url....
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };
    const jwtToken = generateJWTToken(payloadObj, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    // .set( 'content-type', 'application/json')
    // .set('responseType', 'json' as any);
    const url: string = `https://dev-io.renderseo.com/survey/results?timeunit=alltime&periods=current&page=${page}`;

    return this.http.get(url, { headers: headers });
  }

  /**
   * @description: Format Feedback survey data.
   * @returns: List of Feedback survey's
   * @arguments: List of Feedback survey's
   */
  public formatFeedbackSurveyData(surveyList): any[] {
    return surveyList.map((survey) => {
      return {
        ...survey,
        updatedDate: survey.date?.slice(0, 16) || 'N/A',
        address: survey?.address || 'N/A',
        check_number: survey?.check_number || 'N/A',
        comments: survey?.comments || 'N/A',
        contact: survey?.contact || 'N/A',
        date: survey?.date?.slice(0, 16) || 'N/A',
        rating: survey?.rating || 'N/A',
        storecode: survey?.storecode || 'N/A',
        type: survey?.type || 'N/A',
      };
    });
  }
}
