import { Injectable } from "@angular/core";
import { DateRange } from '../models/date-range';

@Injectable({
  providedIn: 'root',
})
export class GraphConfigService {
  public dateRanges = [
    { name: 'Last 3 months', value: { timeunit: 'month', periods: 3 } },
    { name: 'Quarterly comparison', value: { timeunit: 'month', periods: 4 } },
    { name: 'Last 6 months', value: { timeunit: 'month', periods: 6 } },
    { name: 'Last 12 months', value: { timeunit: 'month', periods: 12 } },
    { name: 'Last 24 months', value: { timeunit: 'month', periods: 24 } },
  ];

  public applePopupData = [
    {
      id: 'searchCard',
      label: 'Search Card',
      value: 'searchCard',
    },
    {
      id: 'views',
      label: 'Views',
      value: 'views',
    },
    {
      id: 'directions',
      label: 'Directions',
      value: 'directions',
    },
    {
      id: 'websiteClicks',
      label: 'Website clicks',
      value: 'websiteClicks',
    },
    {
      id: 'calls',
      label: 'Calls',
      value: 'calls',
    },
    {
      id: 'shares',
      label: 'Shares',
      value: 'shares',
    },
    {
      id: 'showCaseClicks',
      label: 'Showcase Clicks',
      value: 'showCaseClicks',
    },
    {
      id: 'appointments',
      label: 'Appointments',
      value: 'appointments',
    },
    {
      id: 'menuCard',
      label: 'Menu',
      value: 'menuCard',
    },
    {
      id: 'ordering',
      label: 'Ordering',
      value: 'ordering',
    },
    {
      id: 'reservations',
      label: 'Reservations',
      value: 'reservations',
    },
  ];

  public yelpPopupConfig = [
    {
      header: 'Business metrics',
      config: [
        {
          id: 'totalPageViews',
          label: 'Total User Views',
          value: 'totalPageViews',
        },
        {
          id: 'directionsAndMapViews',
          label: 'Directions & Map Views',
          value: 'directionsAndMapViews',
        },
        {
          id: 'urlClicks',
          label: 'Clicks to Website',
          value: 'urlClicks',
        },
        {
          id: 'checkIns',
          label: 'Mobile Check-ins',
          value: 'checkIns',
        },
        {
          id: 'userPhotos',
          label: 'User Uploaded Photos',
          value: 'userPhotos',
        },
        {
          id: 'bookmarks',
          label: 'Yelp Bookmarks',
          value: 'bookmarks',
        },
        {
          id: 'desktopCTAClicks',
          label: 'Desktop Call to Action Clicks',
          value: 'desktopCTAClicks',
        },
        {
          id: 'mobileCTAClicks',
          label: 'Mobile Call to Action Clicks',
          value: 'mobileCTAClicks',
        },
        // {
        //   id: 'messagesToBusiness',
        //   label: 'Request a Quote - messages',
        //   value: 'messagesToBusiness',
        // },
        // {
        //   id: 'desktopSearchAppearances',
        //   label: 'Desktop Appearances in Search',
        //   value: 'desktopSearchAppearances',
        // },
        {
          id: 'desktopPageViews',
          label: 'Desktop User Views',
          value: 'desktopPageViews',
        },
        {
          id: 'mobilePageViews',
          label: 'Mobile User Views',
          value: 'mobilePageViews',
        },
        {
          id: 'trackingCalls',
          label: 'Calls Tracked',
          value: 'trackingCalls',
        },
        {
          id: 'dealsSold',
          label: 'Deals Sold',
          value: 'dealsSold',
        },
        {
          id: 'onlineOrders',
          label: 'Online Orders',
          value: 'onlineOrders',
        },
        // {
        //   id: 'onlineBookings',
        //   label: 'Online Bookings',
        //   value: 'onlineBookings',
        // },
        {
          id: 'checkInOfferRedemptions',
          label: 'Check In Offer Redemptions',
          value: 'checkInOfferRedemptions',
        },
        {
          id: 'collectionItemAdded',
          label: 'Collections',
          value: 'collectionItemAdded',
        },
        {
          id: 'rapcInitiated',
          label: 'RaPC Initiated',
          value: 'rapcInitiated',
        },
        {
          id: 'waitlistWisitCreated',
          label: 'Waitlist Visit Created',
          value: 'waitlistWisitCreated',
        },
        {
          id: 'medianResponseTimeInSec',
          label: 'Median response time (secs)',
          value: 'medianResponseTimeInSec',
        },
        {
          id: 'replyRate',
          label: 'Reply rate',
          value: 'replyRate',
        },
        {
          id: 'organicBizPageViews',
          label: 'Organic Page Visits',
          value: 'organicBizPageViews',
        },
        {
          id: 'organicBizPageViewsPercentage',
          label: '% Biz Page Views Organic',
          value: 'organicBizPageViewsPercentage',
        },
        // {
        //   id: 'totalLeads',
        //   label: 'Total # of leads',
        //   value: 'totalLeads',
        // },
      ],
    },
    // {
    //   header: 'Advertiser metrics',
    //   config: [
    //     {
    //       id: 'billedImpressions',
    //       label: 'Billed Ad Impressions',
    //       value: 'billedImpressions',
    //     },
    //     {
    //       id: 'billedClicks',
    //       label: 'Billed Ad Clicks',
    //       value: 'billedClicks',
    //     },
    //     {
    //       id: 'adCost',
    //       label: 'Ad Cost',
    //       value: 'adCost',
    //     },
    //     {
    //       id: 'adDrivenBookmarks',
    //       label: 'Ad Driven Yelp Bookmarks',
    //       value: 'adDrivenBookmarks',
    //     },
    //     {
    //       id: 'adDrivenCalls',
    //       label: 'Ad Driven Mobile Calls',
    //       value: 'adDrivenCalls',
    //     },
    //     {
    //       id: 'adDrivenCTAClicks',
    //       label: 'Ad Driven Total Call to Action Clicks',
    //       value: 'adDrivenCTAClicks',
    //     },
    //     {
    //       id: 'adDrivenCheckIns',
    //       label: 'Ad Driven Mobile Check-ins',
    //       value: 'adDrivenCheckIns',
    //     },
    //     {
    //       id: 'adDrivenDealsSold',
    //       label: 'Ad Driven Deals Sold',
    //       value: 'adDrivenDealsSold',
    //     },
    //     {
    //       id: 'adDrivenDirectionsAndMapViews',
    //       label: 'Ad Driven Directions & Map Views',
    //       value: 'adDrivenDirectionsAndMapViews',
    //     },
    //     {
    //       id: 'adDrivenUserPhotos',
    //       label: 'Ad Driven User Uploaded Photos',
    //       value: 'adDrivenUserPhotos',
    //     },
    //     {
    //       id: 'adDrivenOnlineReservations',
    //       label: 'Ad Driven Online Reservations',
    //       value: 'adDrivenOnlineReservations',
    //     },
    //     {
    //       id: 'adDrivenUrlClicks',
    //       label: 'Ad Driven Clicks to Website',
    //       value: 'adDrivenUrlClicks',
    //     },
    //     {
    //       id: 'adClickThroughRate',
    //       label: 'Ad Click Through Rate',
    //       value: 'adClickThroughRate',
    //     },
    //     {
    //       id: 'averageCostPerClick',
    //       label: 'Average Cost Per Click',
    //       value: 'averageCostPerClick',
    //     },
    //     {
    //       id: 'billableAdClicks',
    //       label: 'Billable Ad Clicks',
    //       value: 'billableAdClicks',
    //     },
    //     {
    //       id: 'billableAdImpressions',
    //       label: 'Billable Ad Impressions',
    //       value: 'billableAdImpressions',
    //     },
    //     {
    //       id: 'adDrivenBizPageViews',
    //       label: 'Ad Driven Page Visits',
    //       value: 'adDrivenBizPageViews',
    //     },
    //     {
    //       id: 'adDrivenCallsTracked',
    //       label: 'Ad Driven Calls Tracked',
    //       value: 'adDrivenCallsTracked',
    //     },
    //     {
    //       id: 'adDrivenRapcInitiated',
    //       label: 'Ad Driven RaPC Initiated',
    //       value: 'adDrivenRapcInitiated',
    //     },
    //     {
    //       id: 'adDrivenWaitlistVisitCreated',
    //       label: 'Ad Driven Waitlist Visit Created',
    //       value: 'adDrivenWaitlistVisitCreated',
    //     },
    //     {
    //       id: 'adDrivenTotalLeads',
    //       label: 'Total # of ad driven leads',
    //       value: 'adDrivenTotalLeads',
    //     },
    //     {
    //       id: 'adDrivenPlatformPurchaseMade',
    //       label: 'Ad Driven Platform Purchases',
    //       value: 'adDrivenPlatformPurchaseMade',
    //     },
    //     {
    //       id: 'adDrivenBizPageViewsPercentage',
    //       label: '% Biz Page Views Ad Driven',
    //       value: 'adDrivenBizPageViewsPercentage',
    //     },
    //   ],
    // },
  ];

  public bingPopupData = [
    {
      id: 'impressions',
      label: 'Impressions',
      value: 'impressions',
    },
    {
      id: 'clicks',
      label: 'Clicks',
      value: 'clicks',
    },
    {
      id: 'clicksWebsite',
      label: 'Clicks: Website',
      value: 'clicksWebsite',
    },
    {
      id: 'clicksDirections',
      label: 'Clicks: Directions',
      value: 'clicksDirections',
    },
    {
      id: 'clicksPhone',
      label: 'Clicks: Phone',
      value: 'clicksPhone',
    },
    {
      id: 'clicksPhotos',
      label: 'Clicks: Photos',
      value: 'clicksPhotos',
    },
    {
      id: 'clicksLocatedAt',
      label: 'Clicks: Located at',
      value: 'clicksLocatedAt',
    },
    {
      id: 'clicksReview',
      label: 'Clicks: Review',
      value: 'clicksReview',
    },
    {
      id: 'clicksSuggestAnEdit',
      label: 'Clicks: Suggest an Edit',
      value: 'clicksSuggestAnEdit',
    },
    {
      id: 'clicksOrderOnline',
      label: 'Clicks: Order Online',
      value: 'clicksOrderOnline',
    },
    {
      id: 'clicksMenu',
      label: 'Clicks: Menu',
      value: 'clicksMenu',
    },
  ];
}
