import { NgModule } from '@angular/core';
import { SurveyToolComponent } from './survey-tool.component';
import { RSCommonModule } from 'src/app/core/feature-modules/facade.module';

const components = [SurveyToolComponent];

@NgModule({
  declarations: [...components],
  imports: [RSCommonModule],
  providers: [],
})

export class SurveyToolModule {}
