<div class="reports_tools">
  <p-accordion [activeIndex]="0">
    <p-accordionTab [header]="'Quick Reports'">
      <div class="blocks">
        <div class="report_inputs">
          <p class="report_inputs_title">Report source:</p>
          <div>
            <ng-container *ngFor="let source of reportSource">
              <button class="btn" [class.active]="source.btnValue === sourceTab"
                [class.inactive]="source.btnValue !== sourceTab"
                [class.disabled]="(source.id === 2 && !disableGoogleInsights) || (source.id === 3 && !disableAppleInsights) || (source.id === 4 && !disableYelpInsights) || (source.id === 5 && !disableBingInsights)"
                [disabled]="(source.id === 2 && !disableGoogleInsights) || (source.id === 3 && !disableAppleInsights) || (source.id === 4 && !disableYelpInsights) || (source.id === 5 && !disableBingInsights)"
                (click)="selectSourceType(source.btnValue)">{{ source.btnName }}
              </button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="blocks">
        <div class="report_inputs">
          <p class="report_inputs_title">Report type:</p>
          <div>
            <ng-container *ngFor="let report of reportsType">
              <button class="btn" [class.active]="report.btnValue === reportType"
                [class.inactive]="report.btnValue !== reportType" (click)="setReportType(report.btnValue)">{{
                report.btnName }}</button>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="blocks">
        <div class="report_inputs">
          <p class="report_inputs_title">Time period:</p>
          <div>
            <p-dropdown name="timeperiod" [options]="filteredData" [(ngModel)]="selectedTimePeriod" optionLabel="label"
              [group]="true" (onChange)="handleChange()">
              <ng-template let-group pTemplate="group">
                <div class="flex align-items-center">
                  <span>{{ group.label }}</span>
                </div>
              </ng-template>
            </p-dropdown>
          </div>
          <div class="custom_date" *ngIf="selectedTimePeriod['value']['timeunit'] === 'period'">
            <p-calendar class="custom_date_calendar" dateFormat="yy/mm/dd" placeholder="Select start date"
              [showIcon]="true" [(ngModel)]="dateValue['start']" (onSelect)="setCustomDate('start')">
            </p-calendar>
            <p-calendar dateFormat="yy/mm/dd" placeholder="Select end date" [showIcon]="true"
              [(ngModel)]="dateValue['end']" (onSelect)="setCustomDate('end')">
            </p-calendar>
          </div>
        </div>
      </div>

      <div class="gen_report">
        <p-splitButton #actions label="Download report" styleClass="export-button" [model]="actionItems"
          (onClick)="actions.onDropdownButtonClick(null)">
        </p-splitButton>
        <button class="gen_report_btn" (click)="viewReport('tableView')">View report</button>
        <button class="gen_report_btn" (click)="viewReport('graphView')" [disabled]="reportType === 'location'" [class.disable]="reportType === 'location'">View Graph Data</button>
        <p-progressSpinner class="spinner" *ngIf="loadingReportView" styleClass="w-2rem h-2rem" strokeWidth="2"
          fill="var(--surface-ground)" animationDuration="spinnerAnimationDuration">
        </p-progressSpinner>
      </div>

      <div class="report_view" *ngIf="viewReportData.length && cols.length">
        <h3 class="report_view_header">Report View</h3>
        <p-table [columns]="cols" [value]="viewReportData" [tableStyle]="{ 'min-width': '50rem' }" [paginator]="true"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" (onPage)="pageChange($event)"
          [rows]="pageSize" [showCurrentPageReport]="true">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns">
                {{ col.header }}
              </th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
              <td *ngFor="let col of columns">
                {{ rowData[col.field] }}
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>

      <div class="chart" *ngIf="graphData.length">
        <ng-container *ngFor="let data of graphData">
          <div class="chart_container" *ngIf="data.visibility">
            <div class="chart_block">
              <h2 class="chart_block_title">{{ data.title }}</h2>
              <p-chart type="bar" [data]="data.graphData" [options]="insightsGraphConfig" width="100%"
                [height]="'250px'">
              </p-chart>
            </div>
          </div>
        </ng-container>
      </div>

    </p-accordionTab>
  </p-accordion>
</div>
