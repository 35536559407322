import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { generateJWTToken } from "src/app/core/helpers/jwt-token.utils";

@Injectable({
  providedIn: 'root',
})
export class AppleMapsSpatialInsightsService {
  constructor(private http: HttpClient) {}

  public getSpatialReport(
    userId: number,
    accountId: number,
    locationId: number,
    month: number,
    year: number
  ): Observable<any> {
    const payload = {
      iss: 'https://platform.renderseo.com',
      sub: userId, // user id
      account_id: accountId,
      iat: new Date().getTime(),
      exp: new Date().getTime() + 3 * 6000,
    };

    const jwtToken = generateJWTToken(payload, '2c4c4d06f0b35b921039d9df2725205d981ac00bf196eb71b02ce7571432e30a');
    const headers = new HttpHeaders().set('Authorization', `${jwtToken}`);
    return this.http.get(
      `https://dev-io.renderseo.com/insights/apple/spatial?location_id=${locationId}&month=${month}&year=${year}`,
      {
        headers: headers,
      }
    );
  };

}
