import { Component, HostListener, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MenuItem, TreeNode } from 'primeng/api';
import { AutoComplete } from 'primeng/autocomplete';
import { OverlayPanel } from 'primeng/overlaypanel';
import { Tree } from 'primeng/tree';
import { Subscription } from 'rxjs';
import { filter, startWith } from 'rxjs/operators';
import { IAccount } from 'src/app/acct-comps/accounts.interfaces';
import { BroadcastService } from '../../../backend-adapter/broadcast.service';
import { DBService } from '../../../backend-adapter/db.service';
import { SessionService } from '../../../backend-adapter/session.service';
import { SocketService } from '../../../backend-adapter/socket.service';
import { NotifyService } from '../../notifications/notify/notify.service';
import { BreadcrumbsBase } from './breadcrumbs.base';
import { NOTIFS } from './notifs.data';

declare var window: any;
declare var document: any;

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class BreadcrumbsComponent extends BreadcrumbsBase implements OnInit {
  @Input() collapsed: boolean;
  @Input() set refreshBreadcrumbEvent(v) {
    if (this.subjInited) {
      // this.refreshBreadcrumb();
    }
    this.subjInited = true;
  }

  private subjInited: boolean;
  showNavbar = false;

  accountList: any[];
  subAcctList: any[];
  account: any;
  currentUser: string;
  effParty_id: number;
  effParty_ids: number[];
  effParties: any[];

  toastNotif: any;

  // Main menu vars
  isShowMenu = false;

  mainAccount: any;

  // autocomplete
  // accountMatches: any[];
  // activeAcct: any;
  // clickedAcct: any;
  // topAcct: any;
  // childAcct: any;

  // autocomplete overlay
  resultsOpen = false;
  // treeAccounts: TreeNode[] = [];
  // selectedNode: TreeNode;
  oldExpandedNode: TreeNode = null;

  @ViewChild('acctAutoComplete') private autoComplete: AutoComplete;
  @ViewChild('op', { static: false }) private op: OverlayPanel;
  @ViewChild('accts') opAccts: Tree;

  // Breadcrumb vars
  breadcrumbItems: MenuItem[] = [];
  showReportsButton = false;

  public selectedAccount: IAccount;

  // notifications
  notifs: any[] = [];
  overlayOpen = false;
  openSettings = false;

  @ViewChild('notifBell', { static: false }) private notifBell: any;
  @ViewChild('notifOverlay', { static: false }) private notifOverlay: any;
  @ViewChild('notifSettings', { static: false }) private notifSettings: any;

  childSub: Subscription;

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.notifBell?.nativeElement.contains(event.target)) {
      this.overlayOpen = !this.overlayOpen;
    } else {
      // if click is outside overlay and overlay is open
      if (!this.notifOverlay?.nativeElement.contains(event.target) && this.overlayOpen) {
        // if click is inside settings popup
        if (this.notifSettings?.nativeElement.contains(event.target)) {
          // clicking on Settings closes both
          if (event.target.innerHTML == 'Settings') {
            this.overlayOpen = false;
            this.openSettings = false;
          } else {
            // clicking on other options on settings popup
            this.overlayOpen = true;
            this.openSettings = false;
          }
        } else {
          // clicking anywhere outside notifications overlay
          this.overlayOpen = false;
          this.openSettings = false;
        }
      }
    }
  }

  constructor(
    public sessionService: SessionService,
    public socketService: SocketService,
    public broadcastService: BroadcastService,
    public dbService: DBService,
    private notifyService: NotifyService,
    protected router: Router,
    protected route: ActivatedRoute
  ) {
    super(router, route);
    window.AppComp = this;
    // tslint:disable-next-line: deprecation
    window.isMac = navigator.appVersion.includes('Mac');
  }

  ngOnInit() {
    // this.refreshBreadcrumb();
    this.listenAccountChanges();
    this.listenRouteChanges();
    this.setSubscriptions();
  }

  public listenRouteChanges(): void {
    this.subs.add(
      this.router.events
        .pipe(filter((e) => e instanceof NavigationEnd))
        .pipe(startWith({ url: this.router.url, urlAfterRedirects: this.router.url }))
        .subscribe((routerEvent: any) => {
          this.buildBreadcrumbsConfig({} /*PARAMS*/, routerEvent, this.refreshBreadcrumb.bind(this));
          // const childRoutes = [this.route.firstChild, this.route.firstChild.firstChild];
          // debugger
          // if (this.childSub) {
          //   this.childSub.unsubscribe();
          // }

          // const routeWithParams = childRoutes.filter((child) => {
          //   return child && Object.keys(child.snapshot.params).length !== 0;
          // })[0];

          // if (routeWithParams) {
          //   this.childSub = routeWithParams.params.subscribe((PARAMS) => {
          //     this.buildBreadcrumbsConfig(PARAMS, routerEvent, this.refreshBreadcrumb.bind(this));
          //   });
          // } else {
          //   this.refreshBreadcrumb();
          // }
        })
    );
  }

  public updateLeftMenu(): void {
    this.isShowMenu = false;
    setTimeout(() => (this.isShowMenu = true), 0);
  }

  private listenAccountChanges() {
    this.subs.add(
      this.sessionService.getSelectedAccount$().subscribe((v) => {
        this.selectedAccount = v;
        this.refreshBreadcrumb();
      })
    );
  }

  setSubscriptions() {
    this.subs.add(
      this.broadcastService.subscribe('sessionAuthenticated', (data) => {
        this.currentUser = this.sessionService.loginObj.loginId;
        this.isShowMenu = true;
        this.checkForNewNotifs();

        this.notifs = NOTIFS;
        // return this.dbService.loadAll();
      })
    );

    this.subs.add(
      this.broadcastService.subscribe('sessionDeauthenticated', () => {
        this.accountChanged(null);
        this.currentUser = null;
        // this.activeAcct = null;
        this.resetSelectedLocation();
      })
    );

    this.subs.add(
      this.broadcastService.subscribe('accountChanged', (repl: any) => {
        this.dbService.clearAccountCaches();
        this.accountChanged(repl.account);
        this.updateLeftMenu();
        this.resetSelectedLocation();

        if (document.URL.includes('parties') && !this.account.properties.hasParties) {
          this.router.navigate(['/dashboard']);
        }
      })
    );

    this.subs.add(
      this.broadcastService.subscribe('accountUpdated', (repl: any) => {
        // this.dbService.Account.loadObjects().then(() => {
        //   this.accountMatches = this.getTreeList();
        // });
        // if (repl.item._parent_id) {
        //   this.account = this.dbService.Account.findById(repl.item._parent_id);
        //   this.changeAccount(this.account.ident);
        // }
      })
    );

    this.subs.add(
      this.broadcastService.subscribe('identityChanged', (repl: any) => {
        this.accountChanged(repl.account);
        this.updateLeftMenu();
        // this.refreshBreadcrumb();
        this.resetSelectedLocation();
        this.partyChanged(repl.effParty_id, repl.effParty_ids);

        if (document.URL.includes('parties') && !this.account.properties.hasParties) {
          this.router.navigate(['/dashboard']);
        }
      })
    );

    // INFO: hided due to redundant usability
    // this.broadcastService.subscribe('accountCollLoaded', (repl: any) => {
    //   this.setAccountList();

    //   // if (this.sessionService && this.sessionService.currAccount) {
    //   //   let currAccount = this.sessionService.currAccount;
    //   //   let fullAccount = this.dbService.Account.findByIdent(currAccount);
    //   //   this.setActiveAcct(fullAccount);
    //   // }
    //   this.setActiveMenuOnNavigation();
    // });

    this.subs.add(
      this.broadcastService.subscribe('locationSelected', (repl: any) => {
        if (this.sessionService.loginObj.loginId == repl.owner) {
          // this.refreshBreadcrumb();
        }
      })
    );

    // tslint:disable-next-line: space-before-function-paren
    this.socketService.onSignal('toastMessage', (sigName, sigArgs: any) => {
      const msgArgs = Object.assign({}, sigArgs);
      msgArgs.severity = msgArgs.severity ?? 'info';
      msgArgs.closable = msgArgs.closable ?? true;
      msgArgs.life = msgArgs.life ?? 3000;
      this.notifyService.add(msgArgs);
    });
  }

  //// notifications
  checkForNewNotifs() {
    for (const notif of this.notifs) {
      if (notif.new) {
        return true;
      }
    }
    return false;
  }

  markAllRead() {
    for (const notif of this.notifs) {
      notif.new = false;
    }

    this.overlayOpen = true;
  }

  deleteAllNotifs() {
    this.notifs = [];
    this.overlayOpen = true;
  }

  goToSettings() {
    this.router.navigate(['/account-settings']);
  }

  toggleSettings() {
    this.openSettings = !this.openSettings;
  }

  trackIndex(index, item) {
    return index;
  }

  deleteEntry(i) {
    this.notifs.splice(i, 1);
  }

  private addAccountToBreadcrumb(): void {
    if (this.selectedAccount) {
      this.breadcrumbItems.push({
        label: this.selectedAccount?.name,
        routerLink: ['/accounts/' + this.selectedAccount?.ident],
      });
    }
  }

  refreshBreadcrumb(config?: MenuItem[]) {
    this.breadcrumbItems = [];

    if (document.URL.includes('dashboard')) {
      this.breadcrumbItems.push({ label: 'Dashboard', routerLink: ['/dashboard'] });
      this.addAccountToBreadcrumb();
    }

    if (document.URL.includes('accounts')) {
      // this.breadcrumbItems.push({ label: 'Accounts' });

      // if (this.sessionService.selectedAccount) {
      //   if (this.selectedAccount && this.selectedAccount._parent_id) {
      //     const parentAccount = this.dbService.Account.coll.find((a) => a._id === this.selectedAccount._parent_id);
      //     this.breadcrumbItems.push({ label: parentAccount?.name });
      //   }
      //   this.breadcrumbItems.push({ label: this.selectedAccount?.name });
      // }

      if (config) {
        let updatedConfig: MenuItem[] = config.map((v) => {
          return v.label.toLowerCase() === this.selectedAccount?.ident.toLowerCase()
            ? { label: this.selectedAccount?.name, routerLink: v?.routerLink }
            : v;
        });
        this.breadcrumbItems = [...this.breadcrumbItems, ...updatedConfig];
      }
    }

    if (document.URL.includes('/locations')) {
      this.breadcrumbItems.push({ label: 'Listings Management' });
      this.addAccountToBreadcrumb();
      if (config) {
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
    }

    if (document.URL.includes('gbp-mgmt')) {
      this.breadcrumbItems.push({ label: 'Listings Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Google Management' });
      if (config) {
        config = config.filter((v) => v.label !== 'Gbp-mgmt');
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
      // this.selectedAccount = null;
    }

    if (document.URL.includes('location-change-log')) {
      this.breadcrumbItems.push({ label: 'Listings Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Locations Change log' });
    }

    if (document.URL.includes('utm-mgmt')) {
      this.breadcrumbItems.push({ label: 'Listings Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'UTM codes' });
    }

    if (document.URL.includes('review-mgmt')) {
      this.breadcrumbItems.push({ label: 'Review Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Reviews & Ratings' });
      if (config) {
        config = config.filter((v) => v.label !== 'Review-mgmt');
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
    }

    if (document.URL.includes('manage-notifications')) {
      this.breadcrumbItems.push({ label: 'Notification Settings' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Manage Notifications' });
      if (config) {
        config = config.filter((v) => v.label !== 'Manage-notifications');
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
    }

    if (document.URL.includes('response-templates')) {
      this.breadcrumbItems.push({ label: 'Review Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Review response templates' });
      if (config) {
        config = config.filter((v) => v.label !== 'Response-templates');
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
    }

    if (document.URL.includes('feedback-survey')) {
      this.breadcrumbItems.push({ label: 'Review Management' });
      this.addAccountToBreadcrumb();
      this.breadcrumbItems.push({ label: 'Feedback surveys' });
      if (config) {
        config = config.filter((v) => v.label !== 'Response-templates');
        this.breadcrumbItems = [...this.breadcrumbItems, ...config];
      }
    }


    if (document.URL.includes('social-mgmt')) {
      this.breadcrumbItems.push({
        label: this.sessionService.hasPerm('social', 'S')
          ? 'Social Media Management'
          : this.sessionService.hasPerm('reputation', 'S')
          ? 'Reputation Management'
          : 'Social/Reputation Management',
      });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('local-ads')) {
      this.breadcrumbItems.push({ label: 'Local Ads' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('reports')) {
      this.breadcrumbItems.push({ label: 'Reports' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('sql-report')) {
      this.breadcrumbItems.push({ label: 'SQL Reports' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('sql-report/')) {
      this.showReportsButton = true;
    } else {
      this.showReportsButton = false;
    }

    if (document.URL.includes('account-settings')) {
      this.breadcrumbItems.push({ label: 'Account Settings' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('finance')) {
      this.breadcrumbItems.push({ label: 'Finance' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('logins')) {
      this.breadcrumbItems.push({ label: 'Admin' });
      this.breadcrumbItems.push({ label: 'Logins' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('permissions')) {
      this.breadcrumbItems.push({ label: 'Admin' });
      this.breadcrumbItems.push({ label: 'Permissions' });
      // this.selectedAccount = null;
    }

    if (document.URL.includes('parties')) {
      this.breadcrumbItems.push({ label: 'Admin' });
      this.breadcrumbItems.push({ label: 'Parties' });
      // this.selectedAccount = null;
    }

    this.breadcrumbItems = this.breadcrumbItems.slice();
  }

  resetSelectedLocation() {
    this.sessionService.selectedLocation = null;
    // INFO: cause issues on logout action due to loginObj equal null
    // this.broadcastService.update('locationSelected', { item: null, owner: this.sessionService.loginObj.loginId });
  }

  resetSelectedAccount() {
    this.sessionService.setSelectedAccount(null);
  }

  backToContents() {
    this.showReportsButton = false;
    this.router.navigate(['/sql-report']);
  }

  // getTreeList() {
  //   let nestedList = this.accountList?.filter((acct) => acct._parent_id == null);
  //   let subaccounts = [];

  //   if (nestedList) {
  //     for (let i = 0; i < nestedList.length; i++) {
  //       this.treeAccounts[i] = {
  //         key: nestedList[i]._id.toString(),
  //         label: nestedList[i].name,
  //         data: nestedList[i].ident,
  //       };

  //       subaccounts = this.dbService.Account.coll
  //         .filter((acct) => acct._parent_id == nestedList[i]._id)
  //         .sort((a, b) => a.name.localeCompare(b.name));

  //       if (subaccounts.length > 0) {
  //         this.treeAccounts[i]["children"] = [];

  //         for (let subaccount of subaccounts) {
  //           this.treeAccounts[i]["children"].push({
  //             key: subaccount._id.toString(),
  //             label: subaccount.name,
  //             data: subaccount.ident,
  //           });
  //         }
  //         subaccounts = [];
  //       }
  //     }
  //   }

  //   return this.treeAccounts;
  // }

  // searchAccts(event) {
  //   //sets this.accountList to list of main accounts
  //   this.setAccountList();

  //   //if no input the account matches get set to nested list
  //   if (event.query.length < 2) {
  //     this.treeAccounts = this.getTreeList();

  //     this.scrollToActiveAcct();

  //   } else if (event.query.length >= 2) {
  //     this.treeAccounts = [];
  //     this.accountMatches = [];
  //     this.subAcctList = [];
  //     let result = new RegExp(event.query, "i");

  //     for (let account of this.accountList) {
  //       this.subAcctList = this.dbService.Account.coll.filter((acct) => acct._parent_id == account._id);

  //       if (account.name.match(result)) {
  //         let node = {};
  //         if (this.subAcctList && this.subAcctList.length > 0) {
  //           let children = [];
  //           for (let subacct of this.subAcctList) {
  //             children.push({
  //               key: subacct._id.toString(),
  //               label: subacct.name,
  //               data: subacct.ident,
  //             });
  //           }

  //           node = {
  //             key: account._id.toString(),
  //             label: account.name,
  //             data: account.ident,
  //             children: children,
  //           };

  //         } else {
  //           node = {
  //             key: account._id.toString(),
  //             label: account.name,
  //             data: account.ident,
  //           };
  //         }
  //         this.accountMatches.push(account);
  //         this.treeAccounts.push(node);
  //       } else {
  //         if (this.subAcctList && this.subAcctList.length > 0) {
  //           let node = {};
  //           let children = [];
  //           for (let subacct of this.subAcctList) {
  //             children.push({
  //               key: subacct._id.toString(),
  //               label: subacct.name,
  //               data: subacct.ident,
  //             });
  //           }

  //           for (let subAcct of this.subAcctList) {
  //             if (subAcct.name.match(result)) {
  //               node = {
  //                 key: account._id.toString(),
  //                 label: account.name,
  //                 data: account.ident,
  //                 children: children,
  //               };
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }

  //   return this.treeAccounts;
  // }

  // accountSearchKeyup(event) {
  //   //on first key press selectedAccount is not set, need to match
  //   if (!this.selectedNode) {
  //     if (this.sessionService.currAccount) {
  //       this.setActiveAcct(this.sessionService.currAccount);
  //     } else {
  //       this.selectedNode = this.treeAccounts[0];
  //     }
  //   }

  //   let matchCount = this.treeAccounts.length;
  //   let matchIndex: number;
  //   for (let acct of this.treeAccounts) {//this only works for top accounts
  //     let account = this.dbService.Account.findByIdent(this.selectedNode.data);
  //     if (!account._parent_id) {
  //       if (acct.data == this.selectedNode.data) {
  //         matchIndex = this.treeAccounts.indexOf(acct);
  //       }
  //     }
  //   }

  //   switch (event.key) {
  //     case "Backspace":
  //       if (event.target.value == "") {
  //         this.treeAccounts = this.getTreeList();
  //       }
  //       break;

  //     case "ArrowDown":
  //       if (matchIndex >= matchCount) {
  //         matchIndex = -1;
  //       }
  //       this.selectedNode = this.treeAccounts[++matchIndex];
  //       setTimeout(() => {
  //         document.getElementsByClassName("p-highlight")[0].scrollIntoView(true);
  //       }, 0);
  //       break;

  //     case "ArrowUp":
  //       if (matchIndex <= 0) {
  //         matchIndex = matchCount;
  //       }
  //       this.selectedNode = this.treeAccounts[--matchIndex];
  //       setTimeout(() => {
  //         document.getElementsByClassName("p-highlight")[0].scrollIntoView(true);
  //       }, 0);
  //       break;

  //     case "Enter":
  //       this.changeAccount(this.selectedNode.data);
  //       let account = this.dbService.findByIdent(this.selectedNode.data);
  //       this.setActiveAcct(account);
  //       setTimeout(() => {
  //         document.getElementsByClassName("p-highlight")[0].scrollIntoView(true);
  //       }, 0);
  //       break;

  //     case "Esc":
  //       this.op.hide();
  //       break;
  //   }
  // }

  // setActiveAcct(acct) {
  //   if (acct?._parent_id) {
  //     this.childAcct = acct;
  //     this.topAcct = this.dbService.Account.findById(acct._parent_id);
  //     this.activeAcct = { label: `${this.topAcct.name} - ${this.childAcct.name}` };
  //     this.selectedNode = {
  //       key: this.childAcct._id.toString(),
  //       label: this.childAcct.name,
  //       data: this.childAcct.ident,
  //     };
  //   } else {
  //     this.topAcct = acct;
  //     this.childAcct = null;
  //     this.activeAcct = { label: this.topAcct.name };
  //     this.selectedNode = {
  //       key: this.topAcct._id.toString(),
  //       label: this.topAcct.name,
  //       data: this.topAcct.ident,
  //     };
  //   }
  //   this.setActiveMenuOnNavigation();
  // }

  // //handles all autocomplete clicks
  // onClick(event, appendTo) {
  //   if (event.target.value == undefined) {//on button click
  //     this.op.toggle(event, appendTo);
  //   } else {//on input click
  //     this.treeAccounts = this.getTreeList();
  //     this.op.show(event, appendTo);

  //     this.scrollToActiveAcct();
  //   }
  // }

  // //handles all situations where overlay panel is hidden, including outside clicks
  // hideOP() {
  //   if (this.sessionService && this.sessionService.currAccount) {
  //     let currAccount = this.sessionService.currAccount;
  //     let fullAccount = this.dbService.Account.findByIdent(currAccount);
  //     this.setActiveAcct(fullAccount);
  //   }
  // }

  // //when overlay panel is active, empty out outocomplete input
  // showOP() {
  //   this.activeAcct = null;
  // }

  // scrollToActiveAcct() {
  //   if (this.sessionService && this.sessionService.currAccount) {
  //     this.setExpandedNode(this.sessionService.currAccount);
  //     setTimeout(() => {
  //       document.getElementsByClassName("p-highlight")[0].scrollIntoView(true);
  //     }, 0);
  //   }
  // }

  // nodeSelected(event) {
  //   this.selectedNode = event.node;
  //   this.clickedAcct = this.dbService.Account.findByIdent(event.node.data);
  //   this.setActiveAcct(this.clickedAcct);

  //   // this.sessionService.currAccount = this.activeAccount.ident;
  //   this.changeAccount(this.clickedAcct.ident);
  //   this.refreshBreadcrumb();

  //   this.autoComplete.overlayVisible = false;
  //   this.op.hide();

  //   // this.setActiveMenuOnNavigation();
  // }

  // close previously expanded node
  // expandNode(event) {
  //   if (this.oldExpandedNode)
  //     this.oldExpandedNode.expanded = false;

  //   this.oldExpandedNode = event.node;
  // }

  // setExpandedNode(ident) {
  //   if (ident.includes('-')) {
  //     let topIdent = ident.split('-')[0];

  //     for (let node of this.treeAccounts) {
  //       if (node.data == topIdent) {
  //         node.expanded = true;
  //       }
  //     }
  //   } else {
  //     for (let node of this.treeAccounts) {
  //       if (node.data == ident) {
  //         node.expanded = true;
  //       }
  //     }
  //   }
  // }

  setAccountList() {
    this.accountList = this.dbService.Account.coll
      .filter((acct) => acct._parent_id == null)
      .sort((a, b) => a.name.localeCompare(b.name));
    // this.accountMatches = this.accountList;
    const items: any[] = [];

    if (this.dbService.Account.coll && this.dbService.Account.coll.length > 0) {
      for (const a of this.accountList) {
        if (a._status == 'A') {
          items.push({
            escape: false,
            label: `<img class="logo" alt="" src="/assets/logo/${a?.ident}.png"> ${a.name}`,
            command: (event) => {
              this.changeAccount(a.ident);
            },
          });
        }
      }

      if (this.sessionService.hasOwnProperty('currAccount') && this.sessionService.currAccount) {
        this.accountChanged(this.sessionService.currAccount);
      }
    }
  }

  changeAccount(ident: string) {
    this.sessionService.changeAccount(ident).subscribe((res) => {
      this.accountChanged(res.account);
    });
  }

  accountChanged(ident: string) {
    if (!ident) {
      this.account = null;
    } else if (!this.account || this.account.ident != ident) {
      this.refreshBreadcrumb();
    }
    this.updateLeftMenu();
  }

  partyChanged(effParty_id: number, effParty_ids: number[]) {
    this.effParty_id = effParty_id;
    this.effParty_ids = effParty_ids;
    window.setTimeout(() => {
      this.dbService.Party.loadObjects().then(
        (repl) => {
          this.effParties = repl.collection;
        },
        (err) => {
          console.log(err);
        }
      );
    }, 0);
  }

  changeUser_DEPRECATED_NOT_USED(event) {
    this.currentUser = event.item.label;
    // this.menuItemsUser[0].label = this.currentUser; // INFO hided due to move menu to standalone component
  }
}
