import { Component, OnInit } from '@angular/core';
import { FeedbackSurveyService } from '../services/survey-tools.service';
import { SessionService } from 'src/app/core/backend-adapter/session.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { NotifyService } from 'src/app/core/layouts/notifications/notify/notify.service';
import { ReviewsService } from '../reviews.service';

@Component({
  selector: 'app-survey-tool',
  templateUrl: './survey-tool.component.html',
  styleUrls: ['./survey-tool.component.scss'],
})
export class SurveyToolComponent implements OnInit {
  private ngUnsubscribe$: Subject<any> = new Subject();
  private userLoginId: number;
  private accountId: number;
  private currentPageNumber: number;

  public actionItems = [
    {
      label: 'CSV',
      command: () => {
        this.exportCSV('csv');
      },
      disabled: false,
    },
    {
      label: 'XLSX',
      command: () => {
        this.exportXLSX('xlsx');
      },
      disabled: false,
    },
  ];
  // List of all feedback surveys.
  public feedbackSurveysList: any[] = [];
  // Main loading state of the page.
  public isLoadingData = false;
  public statisticsList: any[] = [];

  // Paginator properties
  public first: number = 0;
  public rows: number = 20;
  public totalRecords: number = 0;

  constructor(
    private feedbackSurveyService: FeedbackSurveyService,
    private sessionService: SessionService,
    private notifyService: NotifyService,
    private reviewsService: ReviewsService
  ) {}

  ngOnInit(): void {
    this.getCurrentAccountDetails();
    this.getCurrentUserDetails();
    // this.getFeedbackSurveysList();
  }

  /**
   * @description Method to get current account details.
   */
  private getCurrentAccountDetails(): void {
    this.sessionService.getSelectedAccount$().subscribe((account) => {
      this.accountId = account?._id || null;
    });
  }

  /**
   * @description Method to get feedback surveys list.
   */
  private getFeedbackSurveysList(page?: number): void {
    this.isLoadingData = true;
    this.feedbackSurveyService
      .getFeedbackSurveysList(567, this.userLoginId, page)
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe(
        (res) => {
          this.feedbackSurveysList = this.feedbackSurveyService.formatFeedbackSurveyData(res['records']);
          this.totalRecords = res['total'];
          this.statisticsList = res['summary'];
          this.isLoadingData = false;
          this.notifyService.success('Feedback surveys fetched successfully.');
        },
        (err) => {
          this.isLoadingData = false;
          this.notifyService.info('Error while fetching feedback surveys.');
        },
        () => {
          this.isLoadingData = false;
        }
      );
  }

  /**
   * @description Method to export feedback surveys in CSV format.
   * @param format csv format to export feedback surveys.
   */
  exportCSV(format: string): void {
    this.reviewsService.genCSVReport(this.feedbackSurveysList, format, 'feedback-survey');
  }

  /**
   * @description Method to export feedback surveys in XLSX format.
   * @param format xlsx format to export feedback surveys.
   */
  exportXLSX(format: string): void {
    const headers = Object.keys(this.feedbackSurveysList[0]);
    const tableData = this.feedbackSurveysList.map((survey) => {
      return Object.values(survey);
    });
    const xlslDataArray = [headers, ...tableData];
    this.reviewsService.exportAsExcelFile(xlslDataArray, format);
  }

  /**
   * @description Method to handle page change event from paginator.
   * @param event Page change event from paginator
   */
  public pageChange(event): void {
    this.first = event.first;
    this.rows = event.rows;
  }

  public lazyLoadLocations(event) {
    this.currentPageNumber = event.first / 100 + 1;
    this.getFeedbackSurveysList(this.currentPageNumber);
  }

  /**
   * @description: Get the current user details.
   * @returns: void
   * @arguments: void
   */
  private getCurrentUserDetails(): void {
    this.sessionService
      .getCurrentUser$()
      .pipe(takeUntil(this.ngUnsubscribe$))
      .subscribe((user) => {
        this.userLoginId = user?.login?._id;
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe$.next();
    this.ngUnsubscribe$.complete();
  }
}
